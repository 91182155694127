//
// This is the client side entry point for the React app.
//

import React from "react";
import { render, hydrate } from "react-dom";
import { loadableReady } from "@loadable/component";
import { routes } from "./routes.desktop";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import thunk from "redux-thunk";
import { PersistGate } from "redux-persist/integration/react";
import { reducerManager } from "./reducer-manager";
import baseReducer from "./reducers/base.desktop";
import { migrationsDesktop } from "./migration/desktop";
import { initializeSentry, sentryReduxEnhancer } from "../client/utils/sentry/init";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@cars24/turbo-web/lib/theme-provider";

initializeSentry();

const persistKey = "rootDesktop";
const whitelistedKeys = [
    "leadData",
    "settings",
    "location",
    "marketingLeadForm",
    "usedCarLeadInfo",
    "locationPicker",
    "sellOnlineLeadData",
    "dsa",
    "registrationNo",
    "preDeliveryInspectionLeadData"
];

const middlewares = [thunk];
const DEBUG = process.env.NODE_ENV === "development";
const composeEnhancers =
    typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        DEBUG ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name,
            // actionsBlacklist, actionsCreators, serialize...
        }) : compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer);

//
// Redux configure store with Hot Module Reload
//
// const configureStore = initialState => {
//     const store = createStore(persistedReducer, initialState, enhancer);
//     const persistor = persistStore(store);

//     if (module.hot) {
//         module.hot.accept("./reducers/index.desktop", () => {
//             const nextRootReducer = require("./reducers/index.desktop").default;
//             store.replaceReducer(nextRootReducer);
//         });
//     }

//     return { store, persistor };
// };

// const { store, persistor } = configureStore(window.__PRELOADED_STATE__);

const { store, persistor } = reducerManager.configureStore({
    baseReducer,
    initialState: window.__PRELOADED_STATE__,
    enhancer,
    persistKey,
    whitelistedKeys,
    migration: migrationsDesktop
});

const start = App => {
    const jsContent = document.querySelector(".js-content");
    const reactStart = window.__PRELOADED_STATE__ && jsContent.innerHTML ? hydrate : render;

    loadableReady(() =>
        reactStart(
            <HelmetProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        {() => (
                            <BrowserRouter>
                                <ThemeProvider initialTheme="Base">
                                    <App />
                                </ThemeProvider>
                            </BrowserRouter>
                        )}
                    </PersistGate>
                </Provider>
            </HelmetProvider>,
            jsContent
        ));
};

start(() => renderRoutes(routes));
// window.webappStart = () => start(() => renderRoutes(routes));

//
// Hot Module Reload setup
//
if (module.hot) {
    module.hot.accept("./routes.desktop", () => {
        const r = require("./routes.desktop");
        start(() => renderRoutes(r.routes));
    });
}
