import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE

    FETCH_OTHER_YARD_CARS_LIST
    FETCH_OTHER_YARD_CARS_LIST_SUCCESS
    FETCH_OTHER_YARD_CARS_LIST_FAILURE

    UPDATE_SORT_ORDER

    RESET_CAR_LIST

    SET_ACTIVE_SCREEN
    SET_ACTIVE_TAB

    UPDATE_CURRENT_PAGE

    UPDATE_SSR_STATUS

    FETCH_CAR_COUNTS
    FETCH_CAR_COUNTS_SUCCESS
    FETCH_CAR_COUNTS_FAILURE

    UPDATE_IS_REDIRECTED

    UPDATE_CAR_LIST_OPTIONS

    USER_BANNER_STATUS
    ACTIVE_BANNER

    OFF_SCREEN_FILTER_COUNT
    APPLIED_BANNER_FILTER
    CLEAR_APPLIED_BANNER_FILTER
    SET_CF_FORM_ICON
    RELOAD_CAR_LIST
    FETCH_GA_BANNER_COUNT_SUCCESS
    AVAILABLE_COUPONS_FOR_USER
    APPLIED_COUPONS_FOR_USER

    SET_BUYER_TESTIMONIAL
    UPDATE_BUYER_TESTIMONIAL_SSR_STATUS
    SET_BUYER_TESTIMONIAL_FAILURE

    CAR_LISTING_ERROR
    NEAREST_CITIES
    SET_PA_OFFER_FILTER

    FETCH_CAR_COUNTS_SUCCESS
    FETCH_CAR_COUNTS_FAILURE

    FETCH_SIMILAR_CAR_INIT
    FETCH_SIMILAR_CAR_SUCCESS
    FETCH_SIMILAR_CAR_FAILURE
    
    UPDATE_SIMILAR_CAR_FILTER_SSR
    UPDATE_OTHER_CITY_FILTER_SSR
    UPDATE_LISTING_FAQ
    RESET_LISTING_FAQ
    SET_LISTING_FAQ_SSR

    SHARE_LOCATION_POPUP
    USER_HUB_LOCATION
    UPDATE_CAR_LIST_FOR_NON_SERVICEABLE
    UPDATE_LISTING_PAGE_VISITED

    CHANGE_LOCATION_BY_NEARBY
    UPDATE_FILTER_TOOL_TIP_SHOWED

    FETCH_OTHER_CARS
    FETCH_OTHER_CARS_FAILURE
    FETCH_OTHER_CARS_SUCCESS

    FETCH_SIMILAR_CARS_FOR_LISTING_CAR_SUCCESS
    FETCH_SIMILAR_CARS_FOR_LISTING_CAR_FAILURE

    UPDATE_CLICKED_CAR_INDEX

    FETCH_OTHER_CARS
    FETCH_OTHER_CARS_FAILURE
    FETCH_OTHER_CARS_SUCCESS
    UPDATE_OTHER_CARS_SSR_STATUS
    UPDATE_TOTAL_CAR_SIZE
    UPDATE_OTHER_CARS_LISTING

    UPDATE_CMS_DATA

    UPDATE_BEST_MATCH_SNACK_BAR_VIEW_STATUS

    UPDATE_RECOMMENDATION_TRANSFER_STATUS

    UPDATE_MONGO_LISTENING_CONDITIONS

    UPDATE_FILTER_STICKINESS_CONDITION
`,
    {
        prefix: "carlist/"
    }
);
