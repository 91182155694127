/* eslint-disable max-statements */
import queryString from "querystring";
import { API_PATH_ALTERNATE } from "../constants/api-constants";
import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const {
    SEO_CONTENT,
    MAKE,
    MODEL,
    MODEL_BASED_ON_YEAR_AND_MAKE,
    YEAR,
    YEAR_BASED_ON_MAKE,
    KILOMETER,
    VARIANT,
    CITY,
    STATE,
    RECENTLY_INSPECTED,
    TESTIMONIAL,
    CRITIC_REVIEWS,
    MEDIA_LOVE,
    BLOG,
    LEAD_CREATE,
    LEAD_UPDATE,
    BRANCH_BY_CITY,
    APPOINTMENT,
    APPOINTMENT_CONFIRMATION,
    APPOINTMENT_RESCHEDULE,
    OTP_SEND,
    LEAD_OTP_SEND,
    OTP_VERIFY,
    LEAD_OTP_VERIFY,
    LEAD_DETAILS,
    APPOINTMENT_DETAILS,
    GET_VERIFIED,
    BANNER_WIDGET,
    RE_VERIFY_APPOINTMENT,
    FAQ_LIST,
    CUSTOMER_REVIEW,
    CREATE_ONE_STEP_APPOINTMENT,
    UPDATE_ONE_STEP_APPOINTMENT,
    GET_BRANCH_CITY_COUNT,
    CREATE_CUSTOMER_REVIEW,
    UPLOAD_CUSTOMER_IMAGE,
    UPDATE_CUSTOMER_REVIEW,
    REFER_USER,
    SUBMIT_REQUEST_DOWNLOAD_LINK,
    RC_SUMMARY,
    USER_APPOINTMENT_DETAILS,
    CONCERN_LIST,
    CREATE_USER_CONCERN,
    CUSTOMER_REVIEW_LIST,
    RESOLUTION_FEEDBACK,
    RTO_PAGE,
    CONFIG,
    SEO,
    ACKO_LEAD,
    FUEL_PRICE,
    RTO_LIST,
    CONTENT_HOOK,
    REFERRAL_CODE,
    PINCODES,
    SUBMIT_FRANCHISE,
    SUBMIT_LOAN,
    SUBMIT_ONBOARDING,
    CAR_LOAN_SEND_OTP,
    FETCH_FINANCE_POLICIES,
    SAVE_CLASSIFIED_DETAILS,
    UPLOAD_CLASSIFIED_IMAGE,
    CHATBOT_FAQ,
    SAVE_RC_INSURANCE_COPY,
    UPLOAD_VEHICLE_FILE,
    FETCH_ALLIANCE_DETAILS,
    SAVE_ACKO_DETAILS,
    GET_CAR_SERVICEABLE_CITY,
    GET_CAR_SERVICE_VALID_CITY,
    REQUEST_CALLBACK,
    B2C_REQUEST_CALLBACK,
    C24QUOTE_PRICE_INPUT,
    SAVE_LEAD_IMAGES,
    DELETE_UPLOADED_IMAGES,
    SELL_ONLINE_QUESTION,
    VEHICLE_NUMBER,
    BUYER_TESTIMONIAL
} = API_PATH_ALTERNATE;

// isMigrated - This flag refers to the endpoints for which apis have been migrated to newer Java services.

const carApi = (api) => {
    //params: { cmsuri, expand }
    const getCmsPageData = (params) => {
        return api.get(SEO_CONTENT, { params, parse: true, isMigrated: true });
    };

    const fetchBrands = () => {
        return api.get(MAKE, { parse: true, isMigrated: true });
    };

    const fetchFaqs = (params) => {
        return api.get(FAQ_LIST, { params });
    };

    const fetchModels = (makeId) => {
        return api.get(`${MODEL}/${makeId}`, { parse: true, isMigrated: true });
    };

    const fetchModelsBasedOnYearAndMake = (makeId, yearId) => {
        return api.get(`${MAKE}/${makeId}${MODEL_BASED_ON_YEAR_AND_MAKE}${yearId}`, { parse: true, isMigrated: true });
    };

    const fetchYearList = (modelId) => {
        return api.get(`${YEAR}/${modelId}`, { isMigrated: true });
    };

    const fetchYearListBasedOnMake = (makeId) => {
        return api.get(`${MAKE}/${makeId}${YEAR_BASED_ON_MAKE}`, { isMigrated: true });
    };

    const fetchKMList = () => {
        return api.get(KILOMETER, { isMigrated: true });
    };

    //params: { modelId, year }
    const fetchFuelVariant = (params) => {
        return api.get(VARIANT, { params, isMigrated: true });
    };

    const fetchSelectionCities = (params) => {
        return api.get(CITY, { params, parse: true, isMigrated: true });
    };

    const fetchStates = () => {
        return api.get(STATE, { isMigrated: true });
    };

    const fetchRecentlyInspectedCars = (params) => {
        return api.get(RECENTLY_INSPECTED, { params });
    };

    const fetchTestimonials = () => {
        return api.get(TESTIMONIAL);
    };

    const fetchCriticReviews = () => {
        return api.get(CRITIC_REVIEWS);
    };

    const fetchNews = (params) => {
        return api.get(MEDIA_LOVE, { params });
    };

    const fetchBlogs = () => {
        return api.get(BLOG);
    };

    //params: { city_id, type: OPTIONAL }
    // const fetchNearestBranch = (params) => {
    //     return api.get("location/locations-by-city", { params });
    // };

    //post request to create a lead
    const createLead = (query, params) => {
        return api.post(`${LEAD_CREATE}?${query}`, params);
    };

    const updateLead = (query, params) => {
        return api.put(`${LEAD_UPDATE}?${query}`, params);
    };

    const fetchLeadDetails = (token, params = {}) => {
        return api.get(`${LEAD_DETAILS}/${token}`, { params, parse: true });
    };

    const fetchAppointmentDetails = (token) => {
        return api.get(`${APPOINTMENT_DETAILS}/${token}`, { parse: true });
    };

    // const fetchLead = () => {
    //     return api.get("lead/Hw8hbgdmt20tGvDSaA-dLExZnWHLPjn3");
    // };

    const fetchBannerWidget = (params) => {
        const config = {
            headers: {
                "Token": params.token
            }
        };
        return api.get(BANNER_WIDGET, config);
    };

    const fetchBranchesByCityID = (params) => {
        return api.get(BRANCH_BY_CITY, { params, parse: true });
    };

    const createAppointment = (token, params, query = {}) => {
        const stringifiedQuery = queryString.stringify({
            pay: "skip",
            ...query
        });
        return api.post(`${APPOINTMENT}/${token}?${stringifiedQuery}`, params);
    };

    const updateAppointment = (token, params, query = {}) => {
        const stringifiedQuery = queryString.stringify({
            pay: "skip",
            ...query
        });
        return api.put(`${APPOINTMENT}/${token}?${stringifiedQuery}`, params);
    };

    const sendOTP = (params) => {
        return api.get(OTP_SEND, { params });
    };

    const sendLeadOTP = (params) => {
        return api.get(LEAD_OTP_SEND, { params });
    };

    const verifyOTP = (params) => {
        return api.get(`${OTP_VERIFY}?${queryString.stringify(params)}`);
    };

    const verifyLeadOTP = (params) => {
        return api.get(`${LEAD_OTP_VERIFY}?${queryString.stringify(params)}`);
    };

    const appointmentDetails = params => {
        return api.get(APPOINTMENT_CONFIRMATION, { params, parse: true });
    };

    const submitEditForm = (token, params) => {
        return api.put(`${APPOINTMENT_RESCHEDULE}/${token}`, params);
    };

    const getVerifiedOTP = params => {
        return api.get(GET_VERIFIED, params);
    };

    const reVerifyAppointment = params => {
        return api.post(`${RE_VERIFY_APPOINTMENT}?${queryString.stringify((params))}`);
    };

    const fetchReviews = (params) => {
        return api.get(CUSTOMER_REVIEW, { params });
    };

    const createOneStepAppointment = (params, query = {}) => {
        const stringifiedQuery = queryString.stringify({
            pay: "skip",
            ...query
        });
        return api.post(`${CREATE_ONE_STEP_APPOINTMENT}?${stringifiedQuery}`, params);
    };

    const updateOneStepAppointment = (token, params, query = {}) => {
        const stringifiedQuery = queryString.stringify({
            pay: "skip",
            ...query
        });
        return api.put(`${UPDATE_ONE_STEP_APPOINTMENT}/${token}?${stringifiedQuery}`, params);
    };

    const fetchAllBranchCounts = () => {
        return api.get(`${GET_BRANCH_CITY_COUNT}`);
    };

    const submitReview = (params) => {
        return api.post(`${CREATE_CUSTOMER_REVIEW}`, params);
    };

    const updateReview = (params) => {
        return api.put(`${UPDATE_CUSTOMER_REVIEW}`, params);
    };

    const submitReviewImage = (params) => {
        return api.post(`${UPLOAD_CUSTOMER_IMAGE}`, params);
    };

    const sendReferralMessage = (params) => {
        return api.post(`${REFER_USER}`, params);
    };

    const submitRquestDownloadLink = (params) => {
        return api.get(`${SUBMIT_REQUEST_DOWNLOAD_LINK}`, { params });
    };

    const fetchRCSummary = (authToken) => {
        return api.get(RC_SUMMARY, {
            ...(authToken && {
                headers: {
                    "token": `Bearer ${authToken}`
                }
            })
        });
    };

    const fetchUserAppointmentDetails = (params) => {
        return api.get(USER_APPOINTMENT_DETAILS, { params, parse: true });
    };

    const fetchConcerns = (params) => {
        return api.get(CONCERN_LIST, { params, parse: true });
    };

    const submitUserConcern = (params) => {
        return api.post(CREATE_USER_CONCERN, params);
    };

    const fetchSEOReviews = (params) => {
        return api.get(`${CUSTOMER_REVIEW_LIST}`, { params, parse: true });
    };

    const submitResolutionFeedback = (params, query) => {
        return api.post(`${RESOLUTION_FEEDBACK}${query}`, params);
    };

    const fetchRTOPageType = (params) => {
        return api.get(`${RTO_PAGE}`, { params });
    };

    const fetchConfig = (params) => {
        return api.get(`${CONFIG}`, { params, isMigrated: true });
    };

    const fetchSEODetails = (params) => {
        return api.get(`${SEO}`, { params });
    };

    const submitAckoLead = (params) => {
        return api.post(`${ACKO_LEAD}`, params);
    };

    const fetchFuelPrices = (params) => {
        return api.get(`${FUEL_PRICE}`, { params });
    };

    const fetchRTOList = (id) => {
        return api.get(`${RTO_LIST}/${id}`, { isMigrated: true });
    };

    const fetchContentHooks = (id) => {
        return api.get(`${CONTENT_HOOK}?city_id=${id}`);
    };

    const fetchReferralCode = (mobileNumber) => {
        return api.get(`${REFERRAL_CODE}/${mobileNumber}`);
    };

    const fetchPincodes = (params) => {
        return api.get(`${PINCODES}`, { params });
    };

    const submitFranchise = (params) => {
        return api.post(`${SUBMIT_FRANCHISE}`, params);
    };

    const submitLoan = (params) => {
        return api.post(SUBMIT_LOAN, params);
    };

    const submitOnBoarding = (params) => {
        return api.post(SUBMIT_ONBOARDING, params);
    };

    const sendCarLoanOTP = (params) => {
        return api.post(CAR_LOAN_SEND_OTP, params);
    };

    const fetchFinancePolicies = () => {
        return api.get(FETCH_FINANCE_POLICIES);
    };

    const saveClassifiedDetails = (token, params) => {
        return api.post(`${SAVE_CLASSIFIED_DETAILS}/${token}`, params, {
            headers: { "Content-Type": "application/form-data" }
        });
    };

    const saveClassifiedImage = (params) => {
        return api.post(`${UPLOAD_CLASSIFIED_IMAGE}`, params, {
            headers: { "Content-Type": "application/form-data" }
        });
    };

    const getChatbotFAQs = (params) => {
        return api.get(CHATBOT_FAQ, { params });
    };

    const saveRCAndInsuranceCopy = (params) => {
        return api.post(SAVE_RC_INSURANCE_COPY, params);
    };

    const fetchAllianceDetails = (params) => {
        return api.get(FETCH_ALLIANCE_DETAILS, { params });
    };

    const saveAckoDetails = (token, params) => {
        return api.post(`${SAVE_ACKO_DETAILS}/${token}`, params);
    };

    const getCarServiceableCity = (params) => {
        return api.get(GET_CAR_SERVICEABLE_CITY, { params });
    };

    const getCarServiceValidCity = (params) => {
        return api.get(GET_CAR_SERVICE_VALID_CITY, { params });
    };

    const requestCallback = (token, payload) => {
        return api.post(`${REQUEST_CALLBACK}?token=${token}`, payload);
    };

    const postLead = (payload) => {
        return api.post(B2C_REQUEST_CALLBACK, payload);
    };

    const fetchGeoSearchLoactionDetails = (key) => {
        return api.get(`/search-location?key=${key}`);
    };

    const postC24QuoteInput = (payload) => {
        return api.post(`${C24QUOTE_PRICE_INPUT}`, payload);
    };

    const postVehicleDocument = (payload) => {
        return api.post(`${UPLOAD_VEHICLE_FILE}`, payload);
    };

    const saveLeadImages = (token, payload) => {
        return api.post(`${SAVE_LEAD_IMAGES}?token=${token}`, payload);
    };

    const updateVehicleFile = (token, documentType) => {
        return api.get(`${DELETE_UPLOADED_IMAGES}?token=${token}&vehicle_file=${documentType}`);
    };

    const postSellOnlineQuestion = (data) => {
        return api.post(`${SELL_ONLINE_QUESTION}`, data);
    };

    const vehicleNoRegistration = (registrationNo) => {
        return api.get(`/v6${VEHICLE_NUMBER}/${registrationNo}`, {parse: true, isMigrated: true });

    };

    const buyerTestimonial = ({ makeId, modelId, cityId, type }) => {
        return api.get(`${BUYER_TESTIMONIAL}?${type ? `type=${type}` : ""}${makeId ? `&makeId=${makeId}` : ""}${modelId ? `&modelId=${modelId}` : ""}${cityId ? `&cityId=${cityId}` : ""}`, { isMigrated: true });
    };

    const submitLeads = (params) => {
        return api.post(`/leads?${params}`);
    };

    return {
        getCmsPageData,
        fetchBrands,
        fetchModels,
        fetchYearList,
        fetchKMList,
        fetchFuelVariant,
        fetchSelectionCities,
        fetchStates,
        fetchBlogs,
        fetchCriticReviews,
        // fetchNearestBranch,
        fetchNews,
        fetchTestimonials,
        createLead,
        fetchRecentlyInspectedCars,
        fetchBranchesByCityID,
        createAppointment,
        sendOTP,
        sendLeadOTP,
        verifyOTP,
        verifyLeadOTP,
        appointmentDetails,
        updateLead,
        updateAppointment,
        submitEditForm,
        fetchLeadDetails,
        fetchAppointmentDetails,
        getVerifiedOTP,
        // updateLead,
        // fetchLead,
        fetchBannerWidget,
        reVerifyAppointment,
        fetchFaqs,
        fetchReviews,
        createOneStepAppointment,
        updateOneStepAppointment,
        fetchAllBranchCounts,
        submitReview,
        submitReviewImage,
        updateReview,
        sendReferralMessage,
        submitRquestDownloadLink,
        fetchRCSummary,
        fetchUserAppointmentDetails,
        fetchConcerns,
        submitUserConcern,
        fetchSEOReviews,
        submitResolutionFeedback,
        fetchRTOPageType,
        fetchConfig,
        fetchSEODetails,
        submitAckoLead,
        fetchFuelPrices,
        fetchRTOList,
        fetchContentHooks,
        fetchReferralCode,
        fetchPincodes,
        submitFranchise,
        submitLoan,
        submitOnBoarding,
        sendCarLoanOTP,
        fetchFinancePolicies,
        saveClassifiedDetails,
        saveClassifiedImage,
        getChatbotFAQs,
        saveRCAndInsuranceCopy,
        fetchAllianceDetails,
        saveAckoDetails,
        getCarServiceableCity,
        getCarServiceValidCity,
        requestCallback,
        postLead,
        fetchGeoSearchLoactionDetails,
        postC24QuoteInput,
        postVehicleDocument,
        saveLeadImages,
        updateVehicleFile,
        postSellOnlineQuestion,
        vehicleNoRegistration,
        buyerTestimonial,
        submitLeads,
        fetchYearListBasedOnMake,
        fetchModelsBasedOnYearAndMake
    };
};

export const CarService = carApi(middlewareConfig(MIDDLEWARE_ENUMS.BASE_CONST));
