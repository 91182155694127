import { createTypes } from "../../utils/redux/create-types";

export default createTypes(
    `
    SET_POSITION
    
    FETCH_GEO_DETAILS_INIT
    FETCH_GEO_DETAILS_SUCCESS
    FETCH_GEO_DETAILS_FAILURE

    RESET_ERROR
    SET_ERROR

    RESET_LOCATION_DATA

    UPDATE_CITY_LOCATION_DETAILS
`,
    {
        prefix: "location/"
    }
);
