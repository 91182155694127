
import { stringConstants } from "../../constants/app-constants";
import sortByKey from "../helpers/sort-by-key";

export default (list) => {
    const all = sortByKey(list, "display_order", "desc");
    const popular = all.filter(value =>
        value.is_popular && value.is_popular.toLowerCase() === stringConstants.YES
    );
    return { all, popular };
};
