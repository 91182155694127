import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_FILTERS
    FETCH_FILTERS_SUCCESS
    FETCH_FILTERS_FAILURE
    UPDATE_SSR_STATUS
    UPDATE_SSR_APPLIED_FILTER
    UPDATE_SELECTED_ON_SCREEN_FILTER
    UPDATE_FILTER_OPTION_DATA
    UPDATE_APPLIED_FILTERS
    COPY_APPLIED_TO_SELECTED_FILTER
    CLEAR_SELECTED_FILTER_CATEGORY
    CLEAR_APPLIED_FILTER_CATEGORY
    COPY_SELECTED_TO_LAST_APPLIED_FILTERS
    SET_LAST_APPLIED_FILTER
    UPDATE_FILTER_TAB_TYPE
    UPDATE_RECENT_SEARCHES
    SET_INDIA_PAGE
    SET_INDIA_PAGE_URL
    SET_FILTER_SEARCH_QUERY
    SET_FETCHED_FILTER_CITY_ID
    SET_RESET_SELECTED_FILTERS
    SET_RESET_SELECTED_FILTERS_CHECKOUT
    RESET_APPLIED_FILTERS
    CLEAR_BULK_SELECTED_FILTER_CATEGORY
`,
    {
        prefix: "filters/"
    }
);
