import { CatalogGatewayService } from "../../../service/catalog-gateway";
import Types from "./types";

const fetchFiltersInit = () => ({
    type: Types.FETCH_FILTERS
});

const fetchFilterSuccess = (data) => ({
    type: Types.FETCH_FILTERS_SUCCESS,
    ...data
});

const fetchFiltersFailure = () => ({
    type: Types.FETCH_FILTERS_FAILURE
});

const fetchFilters = ({data, clientId}) => (dispatch) => {
    dispatch(fetchFiltersInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.searchFilter({data, clientId})
            .then((response) => {
                dispatch(fetchFilterSuccess(response.data));
                resolve(response.data);
            })
            .catch(() => {
                dispatch(fetchFiltersFailure());
                reject([]);
            });
    });
};

const updateSelectedOnScreenFilter = (selectedOnScreenFilter) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    selectedOnScreenFilter
});

const updateFilterOptionData = (data) => ({
    type: Types.UPDATE_FILTER_OPTION_DATA,
    data
});

const updateAppliedFilters = () => ({
    type: Types.UPDATE_APPLIED_FILTERS
});

const updateSSRAppliedFilters = (data) => ({
    type: Types.UPDATE_SSR_APPLIED_FILTER,
    data
});

const clearAppliedFilterCategory = (data) => ({
    type: Types.CLEAR_APPLIED_FILTER_CATEGORY,
    data
});

const clearSelectedFilterCategory = (data) => ({
    type: Types.CLEAR_SELECTED_FILTER_CATEGORY,
    data
});

const copyAppliedToSelectedFilters = () => ({
    type: Types.COPY_APPLIED_TO_SELECTED_FILTER
});

const updateFilterSSR = (isSSR) => ({
    type: Types.UPDATE_FILTER_SSR,
    isSSR
});

const resetFilters = () => ({
    type: Types.RESET_FILTERS
});

const setIsIndiaPage = (isIndiaPage = true) => ({
    type: Types.SET_INDIA_PAGE,
    isIndiaPage
});

const setIndiaPageUrl = (indiaPageUrl = "") => ({
    type: Types.SET_INDIA_PAGE_URL,
    indiaPageUrl
});

const updateFilterTabType = (activeFilterTab) => ({
    type: Types.UPDATE_FILTER_TAB_TYPE,
    activeFilterTab
});

const updateFilterState = (data) => ({
    type: Types.UPDATE_FILTER_STATE,
    data
});

const setFetchedFilterCityId = (fetchedFilterCityId) => ({
    type: Types.SET_FETCHED_FILTER_CITY_ID,
    fetchedFilterCityId
});

export {
    fetchFilters,
    updateSelectedOnScreenFilter,
    updateFilterOptionData,
    updateAppliedFilters,
    updateSSRAppliedFilters,
    clearAppliedFilterCategory,
    clearSelectedFilterCategory,
    copyAppliedToSelectedFilters,
    updateFilterSSR,
    resetFilters,
    setIsIndiaPage,
    setIndiaPageUrl,
    updateFilterTabType,
    updateFilterState,
    setFetchedFilterCityId,
    fetchFilterSuccess
};
