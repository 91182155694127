import { createTypes } from "../../utils/redux/create-types";

export default createTypes(
    `
    SET_NAME
    SET_PROFILE_NAME
    SET_MOBILE
    SET_PROFILE_MOBILE
    SET_EMAIL
    GET_PROFILE_DETAILS_SUCCESS
    GET_PROFILE_DETAILS_FAILURE
    MARK_UNAUTHORIZED
    CHECK_AUTH
    SET_FIRST_LOAD
    UPDATE_LEAD_TYPE

    SET_SOCKET_ID
    TRUECALLER_PROMPT_COUNT
    UPDATE_WEBVIEW_STATUS

    SET_GA_ID
    UPDATE_CURRENT_PAGE_TYPE

    UPDATE_PROFILE
    UPDATE_PROFILE_SUCCESS
    UPDATE_PROFILE_FAILURE

    SET_LAST_VERIFIED_MOBILE

    FETCH_PINCODE_CONFIG_SUCCESS
    FETCH_PINCODE_CONFIG_FAILURE

    FETCH_USER_COUNTRY
    FETCH_USER_COUNTRY_SUCCESS
    FETCH_USER_COUNTRY_FAILURE

    GET_USER_ADDRESSES_SUCCESS
    GET_USER_ADDRESSES_FAILURE

    CHECK_AUTH_TOKEN

    GET_BOOKING_COUNT
    SET_LOAN_APPLICATION_STATUS
    SET_PRE_APPROVED
    SET_ZERO_DP_ELIGIBLE
    SET_AB_TEST_FLAGS
    SET_EMPLOYMENT_TYPE
    DISABLE_FINANCE_FEEDBACK

    GET_VARIANTS_FROM_B2C_OMS
    SET_HAPTIK_CONTROL_VAR
    SET_BUYER_AB_TEST
    UPDATE_BUYER_AB_TEST_SSR
    BOOKING_LIMIT_EXCEEDED
    SET_PINCODE_SERVICEABLE
    SET_CF_EXPERIMENT_VARIANTS
    SET_BAJAJ_OFFER_STATUS
    SET_CF_USER_DATA
    SET_CF_SNACKBAR_STATUS

    SET_CF_CONFIG
    SET_CALCULATOR_CONFIG
`,
    {
        prefix: "configuration/"
    }
);
