import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_SERVICEABILITY_CHECK
        FETCH_SERVICEABILITY_CHECK_SUCCESS
        FETCH_SERVICEABILITY_CHECK_FAILURE

        FETCH_NEAR_BY_CARS_LIST
        FETCH_NEAR_BY_CARS_LIST_SUCCESS
        FETCH_NEAR_BY_CARS_LIST_FAILURE

        SET_SELECTED_NEAR_BY_CITY
        SET_IS_SERVICEABLE_LOADING
        UPDATE_SSR_STATUS
    `,
    {
        prefix: "nearbyCities/"
    }
);
