import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
   SET_HEADER_VISIBILITY
`,
    {
        prefix: "headerv2/"
    }
);
