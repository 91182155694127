import {
    c2bAuthKey,
    c2cBaseUrl,
    carConsumerGatewayApi,
    checkoutApiUrl,
    couponServiceUrl,
    exchangeApiUrl,
    incidentManagement,
    leadApiUrlKey,
    leadServiceJavaApi,
    lmsBaseApiUrl,
    vehicleApiUrl,
    omsAggregatorApi,
    catalogGatewayUrl,
    apiUrl,
    catalogServiceUrl,
    cmsUrl,
    cmsApiKeys,
    strapiApiUrl,
    strapiAuthKeys,
    catalogUserProfile,
    superAppUrl,
    superAppAuthKeys,
    vasCmsApiKeys,
    newCarsCmsApiKeys
} from "../constants/url-constants";

export const MIDDLEWARE_ENUMS = {
    BASE_CONST: "BASE_CONST",
    USED_CAR_CONST: "USED_CAR_CONST",
    C2C_SECURE_BASE_CONST: "C2C_SECURE_BASE_CONST",
    CHECKOUT_CONST: "CHECKOUT_CONST",
    LMS_CONST: "LMS_CONST",
    VEHICLE_BASE_CONST: "VEHICLE_BASE_CONST",
    CAR_CONSUMER_GATEWAY: "CAR_CONSUMER_GATEWAY",
    LEAD_SERVICE_JAVA_BASE_CONST: "LEAD_SERVICE_JAVA_BASE_CONST",
    INCIDENT_MANAGEMENT: "INCIDENT_MANAGEMENT",
    COUPONS_EXCHANGE_CONST: "COUPONS_EXCHANGE_CONST",
    OMS_AGGREGATOR: "OMS_AGGREGATOR",
    CATALOG_GATEWAY: "CATALOG_GATEWAY",
    CATALOG_SERVICE: "CATALOG_SERVICE",
    CMS_SERVICE: "CMS_SERVICE",
    STRAPI_SERVICE: "STRAPI_SERVICE",
    CATALOG_USER_PROFILE: "CATALOG_USER_PROFILE",
    C2B_SELLER_FINANCE: "C2B_SELLER_FINANCE",
    SUPER_APP_SERVICE: "SUPER_APP_SERVICE",
    VAS_CMS_SERVICE: "VAS_CMS_SERVICE",
    NEW_CAR_CMS_SERVICE: "NEW_CAR_CMS_SERVICE"
};

/**
 * baseApi (String): Api base URL
 * authKey (String): Api key
 * customHeaders (Object): Specific header for the service
 * withCredentials (Boolean): withCredentials flag
 * isVersion (Boolean): If api have different version send this as true and pass version name in api file itself
 * isSource (Boolean): To send Source (mSite/Desktop)
 * isTokenValidCheckEnable (Boolean): To enable token valid check
 * isBearer (Boolean): Authentication as bearer. FYI Basic is default,
 * debugEnv (String): debug ENVIRONMENT
 */

export const middlewareConstants = {
    [MIDDLEWARE_ENUMS.BASE_CONST]: {
        baseApi: `${apiUrl()}/`,
        authKey: c2bAuthKey(),
        customHeaders: {
            "X-Application-Type": "React"
        }
        // withCredentials: true
    },
    [MIDDLEWARE_ENUMS.USED_CAR_CONST]: {
        baseApi: c2cBaseUrl(),
        debugEnv: "development",
        isSource: true
    },
    [MIDDLEWARE_ENUMS.EXCHANGE_CONST]: {
        baseApi: `${exchangeApiUrl()}/`,
        authKey: "YWRtaW46cGFzc3dvcmQ=",
        customHeaders: {
            "X-Application-Type": "React"
        }
    },
    [MIDDLEWARE_ENUMS.C2C_SECURE_BASE_CONST]: {
        baseApi: `${c2cBaseUrl()}/sjwt`,
        isSource: true,
        debugEnv: "development"
    },
    [MIDDLEWARE_ENUMS.CHECKOUT_CONST]: {
        baseApi: `${checkoutApiUrl()}/api/`,
        isSource: true,
        isTokenValidCheckEnable: true,
        isVersion: true
    },
    [MIDDLEWARE_ENUMS.OMS_AGGREGATOR]: {
        baseApi: `${omsAggregatorApi()}/api/`,
        isSource: true,
        isTokenValidCheckEnable: true,
        isVersion: true,
        isClientId: true
    },
    [MIDDLEWARE_ENUMS.LMS_CONST]: {
        baseApi: lmsBaseApiUrl(),
        authKey: leadApiUrlKey(),
        withCredentials: true,
        isBearer: true,
        debugEnv: "development"
    },
    [MIDDLEWARE_ENUMS.VEHICLE_BASE_CONST]: {
        baseApi: vehicleApiUrl(),
        authKey: c2bAuthKey()
    },
    [MIDDLEWARE_ENUMS.CAR_CONSUMER_GATEWAY]: {
        baseApi: carConsumerGatewayApi(),
        customHeaders: {
            "x-client-type": "MWEB"
        }
    },
    [MIDDLEWARE_ENUMS.LEAD_SERVICE_JAVA_BASE_CONST]: {
        baseApi: leadServiceJavaApi(),
        authKey: c2bAuthKey()
    },
    [MIDDLEWARE_ENUMS.INCIDENT_MANAGEMENT]: {
        baseApi: `${incidentManagement()}/api/`,
        isVersion: true
    },
    [MIDDLEWARE_ENUMS.COUPONS_EXCHANGE_CONST]: {
        baseApi: `${couponServiceUrl()}/`,
        isVersion: true,
        isClientId: true
    },
    [MIDDLEWARE_ENUMS.CATALOG_GATEWAY]: {
        baseApi: `${catalogGatewayUrl()}/`,
        isSource: true,
        isClientId: true,
        isCityId: true
    },
    [MIDDLEWARE_ENUMS.CATALOG_SERVICE]: {
        baseApi: `${catalogServiceUrl()}/`,
        isSource: true,
        isClientId: true
    },
    [MIDDLEWARE_ENUMS.CMS_SERVICE]: {
        baseApi: `${cmsUrl()}/`,
        customHeaders: {
            "auth-token": cmsApiKeys()
        }
    },
    [MIDDLEWARE_ENUMS.VAS_CMS_SERVICE]: {
        baseApi: `${cmsUrl()}/`,
        customHeaders: {
            "auth-token": vasCmsApiKeys()
        }
    },
    [MIDDLEWARE_ENUMS.STRAPI_SERVICE]: {
        baseApi: `${strapiApiUrl()}/`,
        customHeaders: {
            "Authorization": `Bearer ${strapiAuthKeys()}`
        }
    },
    [MIDDLEWARE_ENUMS.CATALOG_USER_PROFILE]: {
        baseApi: `${catalogUserProfile()}/`,
        isSource: true,
        isClientId: true
    },
    [MIDDLEWARE_ENUMS.C2B_SELLER_FINANCE]: {
        baseApi: `${carConsumerGatewayApi()}/`
    },
    [MIDDLEWARE_ENUMS.SUPER_APP_SERVICE]: {
        baseApi: `${superAppUrl()}/`,
        customHeaders: {
            "auth-token": superAppAuthKeys()
        }
    },
    [MIDDLEWARE_ENUMS.NEW_CAR_CMS_SERVICE]: {
        baseApi: `${cmsUrl()}/`,
        customHeaders: {
            "auth-token": newCarsCmsApiKeys()
        }
    }
};
