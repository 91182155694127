/* eslint-disable max-len */
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { appUrl } from "../../../constants/url-constants";

export const INITIAL_STATE = {
    home: {
        og: {
            title: "Sell Car Online - Sell Used Cars at Best Price & Get Instant Payment",
            description: "Sell car online at the best price. Get instant online valuation of second hand/used cars and sell your car from the comfort of your home. Call 1800 258 5656 to sell car now at CARS24.",
            url: `${appUrl()}/sell-used-cars/`
        },
        default: {
            description: "Sell your used cars online at the best price. Get an instant online valuation of second-hand cars in 3 easy steps. Get a free RC transfer & Doorstep Pickup",
            title: "Sell Car Online at Best Price & Get Instant Payment - CARS24"
        },
        canonical: `${appUrl()}/sell-used-cars/`
    },
    seo: {
        canonical: `${appUrl()}/sell-used-cars/`
    },
    genericContent: { heading: "", content: "" },
    cityImage: { web: "", mobile: "" },
    identifiers: { make: null, model: null, city: null, location: null },
    mainHeading: "",
    templateType: "",
    isSSR: false,
    isFetched: false,
    tweets: [],
    socialMediaContent: [],
    homePageBanner: "",
    faqList: [],
    cmsContentDetails: {}
};

export const fetchMetaTagsSeo = (state = INITIAL_STATE) => {
    return { ...state, seo: {}, isSSR: false };
};

export const fetchMetaTagsSeoSuccess = (state = INITIAL_STATE,
    { data: { meta, genericContent, cityImage, mainHeading, templateType, identifiers, cmsContentDetails = {}, faqList = [] } }) => {
    return {
        ...state,
        isFetched: true,
        seo: { ...state.seo, ...meta },
        genericContent, cityImage, mainHeading, templateType, identifiers, cmsContentDetails, faqList
    };
};

export const fetchMetaTagsSeoFailure = (state = INITIAL_STATE) => {
    return { ...state, isFetched: true };
};

export const storeTwitterTestimonials = (state = INITIAL_STATE, {data}) => {
    return { ...state, tweets: data };
};

export const updateBGWeb = (state = INITIAL_STATE, { bgImage }) => {
    const cityImage = Object.assign({}, state.cityImage);
    cityImage.web = bgImage;
    return { ...state, cityImage };
};

export const updateBGMobile = (state = INITIAL_STATE, { bgImage }) => {
    const cityImage = Object.assign({}, state.cityImage);
    cityImage.mobile = bgImage;
    return { ...state, cityImage };
};

export const storeSocialMediaContent = (state = INITIAL_STATE, {data}) => {
    return { ...state, socialMediaContent: data };
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return {
        ...state,
        isSSR
    };
};

export const setBannerImage = (state = INITIAL_STATE, { homePageBanner }) => {
    return { ...state, homePageBanner };
};

export const resetMeta = () => {
    return INITIAL_STATE;
};

export const HANDLERS = {
    [Types.FETCH_META_TAGS_SEO]: fetchMetaTagsSeo,
    [Types.FETCH_META_TAGS_SEO_SUCCESS]: fetchMetaTagsSeoSuccess,
    [Types.FETCH_META_TAGS_SEO_FAILURE]: fetchMetaTagsSeoFailure,
    [Types.UPDATE_BG_WEB]: updateBGWeb,
    [Types.UPDATE_BG_MOBILE]: updateBGMobile,
    [Types.STORE_TWITTER_TESTIMONIALS]: storeTwitterTestimonials,
    [Types.STORE_SOCIAL_MEDIA_CONTENT]: storeSocialMediaContent,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.SET_BANNER_IMAGE]: setBannerImage,
    [Types.RESET_META]: resetMeta
};

export default createReducer(INITIAL_STATE, HANDLERS);
