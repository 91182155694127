import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    showLocationPicker: false,
    locationSelectionType: "",
    showLocationPickerV2: false,
    pageType: "",
    isCTAClickedWithoutPincode: {
        type: "",
        meta: {}
    }
};

export const toggleLocationPicker = (state = INITIAL_STATE, {status}) => {
    return { ...state, showLocationPicker: status === undefined ? !state.showLocationPicker : status};
};

export const setLocationSelectionType = (state = INITIAL_STATE, {locationType}) => {
    return { ...state, locationSelectionType: locationType};
};

export const showLocationPickerV2 = (state = INITIAL_STATE, {status}) => {
    return { ...state, showLocationPickerV2: status === undefined ? !state.showLocationPickerV2 : status};
};

export const setLocationPageType = (state = INITIAL_STATE, { pageType }) => {
    return { ...state, pageType };
};

export const setIsCTAClickedWithoutPincode = (state = INITIAL_STATE, { isCTAClickedWithoutPincode }) => {
    return { ...state, isCTAClickedWithoutPincode };
};

export const HANDLERS = {
    [Types.TOGGLE_LOCATION_PICKER]: toggleLocationPicker,
    [Types.TOGGLE_LOCATION_PICKER_V2]: showLocationPickerV2,
    [Types.LOCATION_SELECTETION_TYPE]: setLocationSelectionType,
    [Types.LOCATION_PAGE_TYPE]: setLocationPageType,
    [Types.IS_CTA_CLICKED_WITHOUT_PINCODE]: setIsCTAClickedWithoutPincode

};

export default createReducer(INITIAL_STATE, HANDLERS);
