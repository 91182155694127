import Types from "./types";
import { createReducer } from "../../utils/redux/create-reducer";

export const INITIAL_STATE = {
    position: {
        lat: 0,
        lng: 0
    },
    pincode: null,
    cityId: null,
    cityInfo: {},
    sourceType: null,
    error: null,
    pinAddress: null,
    initialRender: false
};

export const setPosition = (state = INITIAL_STATE, { lat, lng }) => {
    return { ...state, position: { lat, lng } };
};

export const fetchGeoDetailsInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true, error: null };
};

export const fetchGeoDetailsSuccess = (state = INITIAL_STATE, { data, sourceType }) => {
    const {
        pincode,
        coordinates,
        city_info: cityInfo,
        city_id: cityId
    } = data || {};
    return {
        ...state,
        position: { lat: coordinates.latitude, lng: coordinates.longitude },
        isLoading: false,
        pincode,
        cityInfo,
        cityId,
        sourceType,
        pinAddress: `${pincode}, ${cityInfo.city_name}`,
        error: null
    };
};

export const fetchGeoDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isLoading: false, error };
};

export const resetError = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false, error: null };
};

export const setError = (state = INITIAL_STATE, {error}) => {
    return {...state, error};
};

export const resetLocationData = (state = INITIAL_STATE) => {
    return { ...state, cityInfo: {}, cityId: null, pincode: null };
};

export const updateCityLocationDetails = (state = INITIAL_STATE, {data}) => {
    const {pincode, city_id: cityId} = data || {};
    return {
        ...state,
        isLoading: false,
        pincode,
        cityInfo: data,
        cityId,
        sourceType: "pincode",
        pinAddress: `${pincode}, ${data.city_name}`,
        error: null
    };
};

export const HANDLERS = {
    [Types.SET_POSITION]: setPosition,
    [Types.FETCH_GEO_DETAILS_INIT]: fetchGeoDetailsInit,
    [Types.FETCH_GEO_DETAILS_SUCCESS]: fetchGeoDetailsSuccess,
    [Types.FETCH_GEO_DETAILS_FAILURE]: fetchGeoDetailsFailure,
    [Types.RESET_ERROR]: resetError,
    [Types.SET_ERROR]: setError,
    [Types.RESET_LOCATION_DATA]: resetLocationData,
    [Types.UPDATE_CITY_LOCATION_DETAILS]: updateCityLocationDetails
};

export default createReducer(INITIAL_STATE, HANDLERS);
