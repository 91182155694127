import { COMPLEX_FILTERS, NESTED_FILTERS, RANGE_FILTERS } from "./constant";

const createFilters = (appliedFiltersKey, filter) => {
    const filterData = (filter || []).find((f) => f.category === appliedFiltersKey.category);
    if (filterData) {
        const filterOption = appliedFiltersKey.filters.map((options) => {
            let isAllSelected = false;
            const { key, value } = options;
            const preFilters = [];
            const optionData = {
                key,
                value: NESTED_FILTERS.includes(appliedFiltersKey.category) ? value[0] : value,
                operator: filterData.operator,
                isComplexFilter: COMPLEX_FILTERS.includes(appliedFiltersKey.category),
                ...(RANGE_FILTERS.includes(appliedFiltersKey.category) && { isRangeFilter: true })
            };
            preFilters.push(optionData);
            const selectedModal = filterData.options.find((option) => option.filter.value === optionData.value);
            if (options.child) {
                preFilters.push({
                    ...options.child,
                    parentValue: value[0],
                    operator: filterData.subOperator
                });
                isAllSelected = options.child.value.length === selectedModal?.children.length;
            } else if (!options.child && NESTED_FILTERS.includes(appliedFiltersKey.category)) { //All modal is selected is children is empty
                if (selectedModal) {
                    const { filter: { key: subKey } } = selectedModal.children[0];
                    preFilters.push({
                        key: subKey,
                        value: selectedModal.children.map((subOption) => subOption.filter.value),
                        parentValue: value[0],
                        operator: filterData.subOperator
                    });
                    isAllSelected = true;
                }
            }
            return { filters: preFilters, isAllSelected };
        });
        return filterOption;
    }
    return null;
};

export const getPreAppliedFilter = (appliedFilters, filter) => {
    const filters = {};
    for (const appliedFiltersKey of appliedFilters) {
        filters[appliedFiltersKey.category] = {
            selectedFilters: [...createFilters(appliedFiltersKey, filter)].filter(x => !!x)
        };
    }
    return filters;
};
