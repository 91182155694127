/* eslint-disable complexity */
/* eslint-disable max-statements */
import cmsApiParser from "../utils/parsers/cms-api";
import parsePopularAll from "../utils/parsers/popular-brands";
import parseRecentlyInspected from "../utils/parsers/recently-inspected";
import { normalizeKeys } from "../utils/parsers/normalize-keys";
import parseGeoStoreSlots from "../utils/parsers/geo-store-slots-api";
import parseBranchesByCityID from "../utils/parsers/branches-by-city-id-api";
import parseConfirmationDetails from "../utils/parsers/confirmation-details";
import parseCityDetails from "../utils/parsers/city-details";
import parseAppointmentDetails from "../utils/parsers/appointment-details";
import parseUserAppointmentDetails from "../utils/parsers/user-appointment-details";
import parseConcernList from "../utils/parsers/concern-list";
import parseCustomerReviews from "../utils/parsers/customer-reviews";
import parseLeadDetails from "../utils/parsers/lead-details";
import parseInspectionReport from "../utils/parsers/parse-inspection-report";
import parsePickupSlot from "../utils/parsers/parse-pickup-slot";
import rtoCmsApiParser from "../utils/parsers/rto-cms-api";

const API_GET_CONTENT = "/api/v1/content";
const API_MAKE = /\/make$/;
const API_MODEL = /\/make\/((\/\w+)+|\/?)/;
const API_MODEL_BASED_ON_YEAR_MAKE = /\/models\/((\/\w+)+|\/?)/;
const API_RECENTLY_INSPECTED = "get-recently-inspected";
const API_GET_GEO_STORE_SLOT = "get-geo-store-slot";
const API_GET_BRANCHES_BY_ID = "location/locations-by-city";
const API_GET_CONFIRMATION_DETAILS = "appointment/confirmation";
const API_GET_CITIES = "/city";
const API_APPOINTMENT_DETAILS = "appointment/token";
const API_USER_APPOINTMENT_DETAILS = "get-user-appointments";
const API_GET_USER_CONCERN_LIST = "get-concern-list";
const API_GET_CUSTOMER_REVIEWS = "customer-review/get-reviews";
const API_LEAD_DETAILS = "lead-details";
const API_INSPECTION_REPORT = "inspection/changes";
const API_DIY_PICKUP_SLOT = "pickup/slots";
const API_GET_GEO_STORE_SLOT_V2 = "slots/available/store-and-zone";
const RTO_CMS_URI = "rto-vehicle-registration-details";

export default (response) => {
    let parsedResponse = response;
    const path = response.config.url;
    if (path.includes(API_GET_CONTENT)) {
        if (response.config.params && response.config.params.cmsuri && (response.config.params.cmsuri || "").toLowerCase().includes(RTO_CMS_URI)) {
            parsedResponse = rtoCmsApiParser(response.data);
        } else {
            parsedResponse = cmsApiParser(response.data);
        }
    } else if (path.match(API_MAKE)) {
        parsedResponse = normalizeKeys(response.data.detail, "make");
        parsedResponse = parsePopularAll(response.data.detail);
    } else if (path.match(API_MODEL) || path.match(API_MODEL_BASED_ON_YEAR_MAKE)) {
        parsedResponse = normalizeKeys(response.data.detail, "model");
        parsedResponse = parsePopularAll(response.data.detail);
    } else if (path.includes(API_RECENTLY_INSPECTED)) {
        parsedResponse = parseRecentlyInspected(response.data.detail);
    } else if (path.includes(API_GET_GEO_STORE_SLOT) || path.includes(API_GET_GEO_STORE_SLOT_V2)) {
        parsedResponse = parseGeoStoreSlots(response.data.detail);
    } else if (path.includes(API_GET_BRANCHES_BY_ID)) {
        parsedResponse = parseBranchesByCityID(response.data.detail);
    } else if (path.includes(API_GET_CONFIRMATION_DETAILS)) {
        parsedResponse = parseConfirmationDetails(response.data.detail);
    } else if (path.includes(API_GET_CITIES)) {
        parsedResponse = parseCityDetails(response.data.detail);
    } else if (path.includes(API_APPOINTMENT_DETAILS)) {
        parsedResponse = parseAppointmentDetails(response.data.detail);
    } else if (path.includes(API_USER_APPOINTMENT_DETAILS)) {
        parsedResponse = parseUserAppointmentDetails(response.data.detail);
    } else if (path.includes(API_GET_USER_CONCERN_LIST)) {
        parsedResponse = parseConcernList(response.data.detail);
    } else if (path.includes(API_GET_CUSTOMER_REVIEWS)) {
        parsedResponse = parseCustomerReviews(response.data.detail);
    } else if (path.includes(API_LEAD_DETAILS)) {
        parsedResponse = parseLeadDetails(response);
    } else if (path.includes(API_INSPECTION_REPORT)) {
        parsedResponse = parseInspectionReport(response.data.detail);
    } else if (path.includes(API_DIY_PICKUP_SLOT)) {
        parsedResponse = parsePickupSlot(response.data);
    }
    return parsedResponse;
};
