
import { removeDuplicateElementArray } from "../helpers/remove-deplicate-element-array";
import { VIEW_TYPE } from "./constant";

const addInnerSelectedFilter = ({ optionKey, subOptionKey, display, isRangeFilter, isComplexFilter, isSuggestion, isAllSelected }) => {
    return {
        isAllSelected: !!isAllSelected,
        filters: [
            {
                ...optionKey,
                value: (subOptionKey || isRangeFilter || isComplexFilter) ? optionKey.value : [optionKey.value],
                isComplexFilter: !!isComplexFilter,
                ...(isSuggestion && { display })
            },
            ...(subOptionKey && {
                ...subOptionKey,
                value: [subOptionKey.value]
            })
        ].filter(f => f)
    };
};

const checkIfOptionExist = (existingFilter, data) => {
    const isExist = existingFilter.find(e => e.filters.find(f => f.key === data.optionKey.key && (data.subOptionKey ? f.value === data.optionKey.value : true)));
    return isExist;
};

const addNonExistingCategoryfilters = (data, selectedFilters) => {
    const { filterCategory } = data || {};
    let addNonExistingCategoryfilter = {};
    addNonExistingCategoryfilter = {
        ...selectedFilters,
        [filterCategory]: {
            selectedFilters: [
                addInnerSelectedFilter(data)
            ]
        }
    };
    return addNonExistingCategoryfilter;
};

const updateSelectedFilters = (existingFilter, data, isFilterOptionExist) => {
    let updatedFilter = JSON.parse(JSON.stringify(existingFilter));
    if (isFilterOptionExist) {
        updatedFilter.forEach((f) => {
            f.filters.forEach(e => {
                if (!data.subOptionKey) {
                    if (data.isRangeFilter || data.isComplexFilter) {
                        e.value = data.optionKey.value;
                        if (data.isSuggestion)  e.display = data.display;
                        else delete e.display;
                    } else {
                        e.value.push(data.optionKey.value);
                    }
                } else if (e.key === data.subOptionKey.key && e.parentValue === data.subOptionKey.parentValue) {
                    e.value = removeDuplicateElementArray([...e.value, data.subOptionKey.value]);
                    f.isAllSelected = data.isAllSelected;
                }
            });
        });
    } else if (data.viewType === VIEW_TYPE.RADIO) {
        updatedFilter = addInnerSelectedFilter(data);
    } else {
        updatedFilter.push(addInnerSelectedFilter(data));
    }
    return updatedFilter;
};

const updateExistingCategoryfilters = (data, selectedFilters) => {
    const { filterCategory, isComplexFilter } = data || {};
    const isFilterOptionExist = isComplexFilter ? !isComplexFilter : !!checkIfOptionExist(selectedFilters[filterCategory].selectedFilters, data);
    const updateSelectedFilter = updateSelectedFilters(selectedFilters[filterCategory].selectedFilters, data, isFilterOptionExist);
    const updateExistingCategoryfilter = {
        ...selectedFilters,
        [filterCategory]: {
            selectedFilters: [
                ...updateSelectedFilter
            ].filter(f => f)

        }
    };
    return updateExistingCategoryfilter;
};

const addFilter = (data, selectedFilters) => {
    const { filterCategory } = data || {};
    if (!selectedFilters[filterCategory]) {
        return addNonExistingCategoryfilters(data, selectedFilters);
    } else {
        return updateExistingCategoryfilters(data, selectedFilters);
    }
};

const removeSelectedFilter = (existingFilter, data) => {
    const updatedFilter = JSON.parse(JSON.stringify(existingFilter || []));
    let emptyElementIndex = -1;
    updatedFilter.forEach((f, i) => {
        f.filters.forEach(e => {
            if (data.isComplexFilter && e.key === data.optionKey.key && JSON.stringify(e.value) === JSON.stringify(data.optionKey.value)) {
                e.value = JSON.stringify(e.value) !== JSON.stringify(data.optionKey.value);
            } else if (!data.subOptionKey) {
                e.value = e.value.filter(optionKey => optionKey !== data.optionKey.value);
            } else if (e.key === data.subOptionKey.key) {
                e.value = e.value.filter(optionKey => optionKey !== data.subOptionKey.value);
            }
            if (e.key === data.optionKey.key && e.value === data.optionKey.value) {
                f.isAllSelected = data.isAllSelected;
            }
            if (!e.value.length) {
                emptyElementIndex = i;
            }
        });
    });
    if (emptyElementIndex > -1) updatedFilter.splice(emptyElementIndex, 1);
    return updatedFilter;
};

const removedFilter = (data, selectedFilters) => {
    const { filterCategory } = data || {};
    const updateExistingCategoryfilter = {
        ...selectedFilters,
        [filterCategory]: {
            selectedFilters: [
                ...removeSelectedFilter(selectedFilters[filterCategory]?.selectedFilters, data)
            ].filter(f => f)
        }
    };
    if (!updateExistingCategoryfilter[filterCategory]?.selectedFilters.length) {
        delete updateExistingCategoryfilter[filterCategory];
    }
    return updateExistingCategoryfilter;
};

export const getUpdatedAppliedFilters = (data, selectedFilters) => {
    const { isSelected, isRangeFilter } = data;
    if (isSelected || isRangeFilter) {
        return addFilter(data, selectedFilters);
    } else {
        return removedFilter(data, selectedFilters);
    }
};

export const cleatSelectedFilterCategory = (category, selectedFilters) => {
    // eslint-disable-next-line no-unused-vars
    const removeKey = (key, { [key]: _, ...rest }) => rest;
    return removeKey(category, selectedFilters);
};

export const clearBulkSelectedFilters = (categories, selectedFilters) => {
    // eslint-disable-next-line no-unused-vars
    const removeKey = (key, { [key]: _, ...rest }) => rest;

    let updatedFilters = { ...selectedFilters };
    categories.forEach(category => {
        updatedFilters = removeKey(category, updatedFilters);
    });

    return updatedFilters;
};
