import { UsedCarService } from "../../service/used-car-service";
import Types from "./types";

const fetchBuyCarPincodeConfigSuccess = (data) => ({
    type: Types.FETCH_BUY_CAR_PINCODE_CONFIG_SUCCESS,
    data
});

const fetchBuyCarPincodeConfigFailure = () => ({ type: Types.FETCH_BUY_CAR_PINCODE_CONFIG_FAILURE });

const fetchBuyCarPincodeConfig = (pincode) => dispatch => {
    UsedCarService.fetchPincodeConfig(pincode)
        .then(response => {
            dispatch(fetchBuyCarPincodeConfigSuccess(response.data.data));
        })
        .catch(error => {
            dispatch(fetchBuyCarPincodeConfigFailure(error));
        });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const updatePincodeSSRStatus = (isPincodeSSR) => ({
    type: Types.UPDATE_PINCODE_SSR_STATUS,
    isPincodeSSR
});

export {
    fetchBuyCarPincodeConfig,
    updateSSRStatus,
    updatePincodeSSRStatus
};
